// src/pages/UserRegister.js
import React, { useState } from 'react';
import axios from 'axios';

const UserRegister = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/user/register', { username, password });
      alert('Utente creato con successo');
      window.location.href = '/user/login';
    } catch (error) {
      alert("Errore nella creazione dell'utente");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button type="submit">Registrati</button>
    </form>
  );
};

export default UserRegister;
