import React from 'react';
import './Presentazione.css'; // Assicurati di importare il CSS per gli stili specifici

const Presentazione = () => {
  return (
    <div className="presentazione-content-container">
      <h2>Benvenuti</h2>
      <p>Ciao! Sono Giorgio Pasquin, un ingegnere specializzato in ingegnere civile. Benvenuti nella mia pagina di presentazione.</p>
      <h2>Chi Sono</h2>
      <p>[Breve descrizione di te stesso, delle tue esperienze e delle tue passioni]</p>
      <h2>Progetti</h2>
      <ul>
        <li>Progetto 1: Descrizione del progetto 1</li>
        <li>Progetto 2: Descrizione del progetto 2</li>
        <li>Progetto 3: Descrizione del progetto 3</li>
      </ul>
      <h2>Pubblicazioni</h2>
      <ul>
        <li>Pubblicazione 1: Dettagli della pubblicazione 1</li>
        <li>Pubblicazione 2: Dettagli della pubblicazione 2</li>
        <li>Pubblicazione 3: Dettagli della pubblicazione 3</li>
      </ul>
      <h2>Contatti</h2>
      <p>Email: ing.giorgiopasquin@gmail.com</p>
      <p>Telefono: 3479541229</p>
      <p>LinkedIn: <a href="https://linkedin.com/in/giorgiopasquin">Giorgio Pasquin</a></p>
    </div>
  );
};

export default Presentazione;
