import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png'; // Assicurati che il percorso del logo sia corretto

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        closeDropdown();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-content">
        <div className="logo-title">
          <h1>Ingegnere Giorgio Pasquin</h1>
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <nav className="nav">
          <NavLink exact to="/" activeClassName="active-tab" className="nav-link">Home</NavLink>
          <NavLink to="/presentazione" activeClassName="active-tab" className="nav-link">Presentazione</NavLink>
          <NavLink to="/corsi" activeClassName="active-tab" className="nav-link">Corsi di formazione</NavLink>
          <div className="dropdown">
            <span className="nav-link dropdown-button" onClick={toggleDropdown}>Ripetizioni</span>
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
              <NavLink to="/ripetizioni/a047" activeClassName="active-tab" className="nav-link">A047 - Matematica applicata</NavLink>
              <NavLink to="/ripetizioni/a028" activeClassName="active-tab" className="nav-link">A026 - Matematica</NavLink>
              <NavLink to="/ripetizioni/a027" activeClassName="active-tab" className="nav-link">A020 - Fisica</NavLink>
              <NavLink to="/ripetizioni/a029" activeClassName="active-tab" className="nav-link">A027 - Matematica e fisica</NavLink>
            </div>
          </div>
          <NavLink to="/materiale-ripetizioni" activeClassName="active-tab" className="nav-link">Materiale Ripetizioni</NavLink>
          <NavLink to="/role-selection" activeClassName="active-tab" className="nav-link">Login</NavLink> {/* Modifica qui */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
