import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import courses from '../data/courses';
import './CourseDetails.css';

const CourseDetails = () => {
  const { courseId } = useParams();
  const course = courses.find(c => c.id === courseId);
  const [participant, setParticipant] = useState({
    name: '',
    fiscalCode: '',
    birthPlace: '',
    province: '',
    birthDate: '',
    role: '',
    signature: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setParticipant({ ...participant, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Invio:', { course: course.title, participant });

    try {
      const response = await axios.post('http://localhost:5000/api/registrations', { course: course.title, participant });
      console.log('Response from backend:', response.data);
      setRegistrationSuccess(true);
    } catch (error) {
      console.error('Error registering participant:', error.response?.data?.message || error.message);
      alert('Error registering participant');
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setRegistrationSuccess(false);
  };

  return (
    <div className="course-details">
      <div className="course-info">
        <h1>{course.title}</h1>
        <p><strong>Location:</strong> {course.location}</p>
        <p><strong>Status:</strong> {course.status}</p>
        <p><strong>Date:</strong> {course.date}</p>
        <p><strong>Program:</strong> {course.program}</p>
        <button onClick={openModal} className="open-modal-button">Registrati</button>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            {registrationSuccess ? (
              <div>
                <h2>Registrazione avvenuta con successo!</h2>
                <p>La tua registrazione al corso "{course.title}" è stata completata con successo.</p>
                <button onClick={closeModal}>Chiudi</button>
              </div>
            ) : (
              <div>
                <h2>Registrazione</h2>
                <form className="registration-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">COGNOME E NOME</label>
                    <input type="text" id="name" name="name" value={participant.name} onChange={handleInputChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fiscalCode">Codice Fiscale</label>
                    <input type="text" id="fiscalCode" name="fiscalCode" value={participant.fiscalCode} onChange={handleInputChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="birthPlace">Comune o stato straniero di nascita</label>
                    <input type="text" id="birthPlace" name="birthPlace" value={participant.birthPlace} onChange={handleInputChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="province">Prov.</label>
                    <input type="text" id="province" name="province" value={participant.province} onChange={handleInputChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="birthDate">Data di nascita</label>
                    <input type="date" id="birthDate" name="birthDate" value={participant.birthDate} onChange={handleInputChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="role">Mansione</label>
                    <input type="text" id="role" name="role" value={participant.role} onChange={handleInputChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="signature">Firma Partecipante</label>
                    <input type="text" id="signature" name="signature" value={participant.signature} onChange={handleInputChange} required />
                  </div>
                  <button type="submit">Invio Registrazione</button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDetails;
