import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Aggiungi questa riga
import './Login.css'; // Ensure the correct path to your CSS file
import logo from '../assets/logo.png'; // Ensure the correct path to your logo

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/admin/login', { username, password });
      localStorage.setItem('token', response.data.token);
      window.location.href = '/admin/dashboard';
    } catch (error) {
      alert('Credenziali non valide');
    }
  };

  const handleForgotPasswordClick = () => {
    // Logic for handling forgotten password, e.g., redirecting to another page or showing a modal
    alert('Password recovery process');
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <img src={logo} alt="Logo" className="logo" />
        <h2>Amministratore Login</h2>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
        <button className="forgot-password" onClick={handleForgotPasswordClick}>
          Password dimenticata?
        </button>
        {/* Link per la registrazione */}
        <p>
          Non hai un profilo? <Link to="/admin/register">Registrati qui</Link>
        </p>
      </form>
    </div>
  );
};

export default AdminLogin;
