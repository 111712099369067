import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './MaterialeRipetizioni.css';

const MaterialeRipetizioni = ({ titolo, codice, descrizione, materiale }) => {
  // const { categoria } = useParams();
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop();

  return (
    <div className="materiale-ripetizioni-container">
      <h1>{codice} - {titolo}</h1>
      <p>{descrizione}</p>
      
      <nav className="materiale-nav">
        <NavLink to={`/ripetizioni/${codice}/video`} className={({ isActive }) => isActive ? "active" : ""}>Video</NavLink>
        <NavLink to={`/ripetizioni/${codice}/slide`} className={({ isActive }) => isActive ? "active" : ""}>Slide</NavLink>
        <NavLink to={`/ripetizioni/${codice}/documenti`} className={({ isActive }) => isActive ? "active" : ""}>Documenti</NavLink>
      </nav>

      <div className="materiale-content">
        {currentPath && currentPath !== codice && (
          <>
            <h2>{currentPath.charAt(0).toUpperCase() + currentPath.slice(1)}</h2>
            <ul className="materiale-list">
              {materiale[currentPath] && materiale[currentPath].map((item, index) => (
                <li key={index}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default MaterialeRipetizioni;
