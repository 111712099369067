import React from 'react';
import CourseCard from '../components/CourseCard';
import './Corsi.css'; // Ensure you have the necessary CSS
import courses from '../data/courses'; // Import courses data

const Corsi = () => {
  return (
    <div className="corsi-content-container">
      <h2>Prossimi Corsi in Aula e/o Videoconferenza</h2>
      <div className="courses-grid">
        {courses.map((course) => (
          <CourseCard key={course.id} {...course} />
        ))}
      </div>
    </div>
  );
};

export default Corsi;
