import React from "react";

const Home = () => {
  return (
    <section id="home">
      <h2>Benvenuti</h2>
      <p>
        Ciao! Sono Giorgio Pasquin, un ingegnere specializzato in ingegnere
        civile. Benvenuti nella mia pagina di presentazione.
      </p>
    </section>
  );
};

export default Home;
