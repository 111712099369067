import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [courses, setCourses] = useState([]);
  const [newCourse, setNewCourse] = useState({ title: '', description: '', date: '', location: '' });
  const navigate = useNavigate();

  useEffect(() => {
    // Funzione per ottenere la lista dei corsi dal backend
    const fetchCourses = async () => {
      try {
        const response = await axios.get('/api/admin/courses', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCourses(response.data);
      } catch (error) {
        console.error('Errore nel recuperare i corsi', error);
      }
    };

    fetchCourses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCourse({ ...newCourse, [name]: value });
  };

  const handleAddCourse = async () => {
    try {
      const response = await axios.post('/api/admin/courses', newCourse, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCourses([...courses, response.data]);
      setNewCourse({ title: '', description: '', date: '', location: '' }); // Resetta il form
    } catch (error) {
      console.error('Errore nell\'aggiungere il corso', error);
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      await axios.delete(`/api/admin/courses/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCourses(courses.filter(course => course._id !== id));
    } catch (error) {
      console.error('Errore nell\'eliminare il corso', error);
    }
  };

  const handleEditCourse = (id) => {
    navigate(`/admin/courses/edit/${id}`);
  };

  return (
    <div>
      <h1>Dashboard Amministratore</h1>

      <h2>Corsi</h2>
      <ul>
        {courses.map(course => (
          <li key={course._id}>
            <h3>{course.title}</h3>
            <p>{course.description}</p>
            <p>{course.date}</p>
            <p>{course.location}</p>
            <button onClick={() => handleEditCourse(course._id)}>Modifica</button>
            <button onClick={() => handleDeleteCourse(course._id)}>Elimina</button>
          </li>
        ))}
      </ul>

      <h2>Aggiungi un nuovo corso</h2>
      <input
        type="text"
        name="title"
        placeholder="Titolo"
        value={newCourse.title}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="description"
        placeholder="Descrizione"
        value={newCourse.description}
        onChange={handleInputChange}
      />
      <input
        type="date"
        name="date"
        placeholder="Data"
        value={newCourse.date}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="location"
        placeholder="Location"
        value={newCourse.location}
        onChange={handleInputChange}
      />
      <button onClick={handleAddCourse}>Aggiungi Corso</button>
    </div>
  );
};

export default AdminDashboard;
