// RoleSelection.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RoleSelection.css';

const RoleSelection = () => {
  const navigate = useNavigate();

  const handleAdminClick = () => {
    navigate('/admin/login');
  };

  const handleUserClick = () => {
    navigate('/user/login');
  };

  return (
    <div className="role-selection-container">
      <h2>Seleziona il tuo ruolo</h2>
      <div className="role-buttons">
        <button className="role-button admin" onClick={handleAdminClick}>
          Amministratore
        </button>
        <button className="role-button user" onClick={handleUserClick}>
          Utente
        </button>
      </div>
    </div>
  );
};

export default RoleSelection;
