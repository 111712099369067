const courses = [
    {
      id: '1', // Unique IDs for your courses
      title: 'CORSO ADDETTI CARRELLI INDUSTRIALI SEMOVENTI',
      location: 'IN AULA',
      status: 'AVVIO CONFERMATO',
      image: 'path/to/image1.jpg',
      date: '2023-08-06', // Example date
      program: 'Program details here...', // Example program
    },
    {
      id: '2',
      title: 'CORSI DI AGGIORNAMENTO PRIMO SOCCORSO',
      location: 'IN AULA',
      status: 'AVVIO CONFERMATO',
      image: 'path/to/image2.jpg',
      date: '2023-08-07',
      program: 'Program details here...',
    },
    {
      id: '3',
      title: 'CORSO DI AGGIORNAMENTO SULLA SICUREZZA DEI LAVORATORI',
      location: 'IN VIDEOCONFERENZA',
      status: 'AVVIO CONFERMATO',
      image: 'path/to/image3.jpg',
      date: '2023-08-08',
      program: 'Program details here...',
    },
    {
      id: '4',
      title: 'CORSI AGGIORNAMENTO RLS: IL RUOLO E I COMPITI',
      location: 'IN VIDEOCONFERENZA',
      status: 'AVVIO IN CONFERMA',
      image: 'path/to/image4.jpg',
      date: '2023-08-22',
      program: 'Program details here...',
    },
  ];
  
  export default courses;
  