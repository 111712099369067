import React, { useState } from 'react';
import axios from 'axios'; // Importa axios direttamente
import './Register.css';
import logo from '../assets/logo.png';

const AdminRegister = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Le password non coincidono!');
      return;
    }

    try {
      // Usa l'URL completo del tuo backend
      await axios.post('http://localhost:3003/api/admin/register', { username, email, password });
      alert('Registrazione avvenuta con successo! Ora puoi effettuare il login.');
      window.location.href = '/admin/login';
    } catch (error) {
      console.error('Errore durante la registrazione:', error.response?.data || error.message);
      setError(error.response?.data?.message || 'Errore durante la registrazione');
    }
  };

  return (
    <div className="registration-container">
      <form className="registration-form" onSubmit={handleSubmit}>
        <img src={logo} alt="Logo" className="logo" />
        <h2>Registrazione Amministratore</h2>
        {error && <p className="error-message">{error}</p>}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Conferma Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Registrati</button>
        <p>
          Hai già un profilo? <a href="/admin/login">Accedi qui</a>
        </p>
      </form>
    </div>
  );
};

export default AdminRegister;