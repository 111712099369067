import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ParticipantsList.css';

const ParticipantsList = () => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const response = await axios.get('/api/participants');
        setParticipants(response.data);
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
    };

    fetchParticipants();
  }, []);

  return (
    <div className="participants-list">
      <h1>Participants List</h1>
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>COGNOME E NOME</th>
            <th>Codice Fiscale</th>
            <th>Comune o stato straniero di nascita</th>
            <th>Prov.</th>
            <th>Data di nascita</th>
            <th>Mansione</th>
            <th>Firma Partecipante</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{participant.name}</td>
              <td>{participant.fiscalCode}</td>
              <td>{participant.birthPlace}</td>
              <td>{participant.province}</td>
              <td>{participant.birthDate}</td>
              <td>{participant.role}</td>
              <td>{participant.signature}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ParticipantsList;
