import React from 'react';
import { Link } from 'react-router-dom';
import './CourseCard.css';

const CourseCard = ({ id, title, location, status, image }) => {
  return (
    <div class="course-card-container">
      <div class="course-card">
        <Link to={`/courses/${id}`}>
          <img src={image} alt={title} />
          <h3>{title}</h3>
          <p>Location: {location}</p>
          <p>Status: {status}</p>
          <button>View Details</button>
        </Link>
      </div>
    </div>
  );
};

export default CourseCard;
