import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Presentazione from './pages/Presentazione';
import Corsi from './pages/Corsi';
import CourseDetails from './pages/CourseDetails';
import MaterialeRipetizioni from './pages/MaterialeRipetizioni';
import ParticipantsList from './pages/ParticipantsList';
import AdminLogin from './pages/AdminLogin';
import AdminRegister from './pages/AdminRegister'; // Aggiungi questa riga
import UserLogin from './pages/UserLogin'; // Aggiungi questa riga
import UserRegister from './pages/UserRegister'; // Aggiungi questa riga
import RoleSelection from './pages/RoleSelection'; // Aggiungi questa riga
import AdminDashboard from './pages/AdminDashboard'; // Supponiamo che tu abbia una dashboard
import AdminRoute from './components/AdminRoute'; // Rotta protetta
import './App.css';

const App = () => {
  const materiale = {
    video: [
      { title: 'Introduzione alla Matematica Applicata', url: '#' },
      { title: 'Calcolo Differenziale', url: '#' },
    ],
    slide: [
      { title: 'Fondamenti di Algebra Lineare', url: '#' },
      { title: 'Ottimizzazione Matematica', url: '#' },
    ],
    documenti: [
      { title: 'Dispense di Analisi Numerica', url: '#' },
      { title: 'Esercizi di Statistica', url: '#' },
    ],
  };

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/presentazione" element={<Presentazione />} />
        <Route path="/corsi" element={<Corsi />} />
        <Route path="/courses/:courseId" element={<CourseDetails />} />
        <Route path="/materiale-ripetizioni" element={<MaterialeRipetizioni titolo="Materiale Ripetizioni" codice="MR" descrizione="Materiale per le ripetizioni" materiale={materiale} />} />
        <Route path="/ripetizioni/:codice/:categoria/*" element={<MaterialeRipetizioni titolo="Matematica Applicata" codice="A047" descrizione="Materiale per la Matematica Applicata" materiale={materiale} />} />
        <Route path="/participants" element={<ParticipantsList />} />
        
        {/* Rotte per il login e la registrazione */}
        <Route path="/role-selection" element={<RoleSelection />} /> {/* Aggiungi la rotta per la selezione del ruolo */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/register" element={<AdminRegister />} /> 
        <Route path="/user/login" element={<UserLogin />} /> {/* Aggiungi la rotta per il login utente */}
        <Route path="/user/register" element={<UserRegister />} /> {/* Aggiungi la rotta per la registrazione utente */}
        <Route path="/admin/dashboard" element={
          <AdminRoute>
            <AdminDashboard />
          </AdminRoute>
        } />
      </Routes>
    </Router>
  );
};

export default App;
