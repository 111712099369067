import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const AdminRoute = ({ element: Component, ...rest }) => (
  <Route
    {...rest}
    element={
      localStorage.getItem('token') ? (
        <Component />
      ) : (
        <Navigate to="/admin/login" />
      )
    }
  />
);

export default AdminRoute;
